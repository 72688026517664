import img1 from '../../../assets/images/carousel-1.jpeg'
import img2 from '../../../assets/images/carousel-2.jpg'
import img3 from '../../../assets/images/carousel-3.jpg'

const CarouselData = [
    {
        imgLink: img1,
        title: 'your title',
        overview: 'lorem ipsum dolar sit amet'
    },
    {
        imgLink: img2,
        title: 'your title',
        overview: 'lorem ipsum dolar sit amet'
    },
    {
        imgLink: img3,
        title: 'your title',
        overview: 'lorem ipsum dolar sit amet'
    },
]

export default CarouselData