import ProductPhoto1 from '../../../assets/images/product-1.png';
import ProductPhoto2 from '../../../assets/images/product-2.png';
import ProductPhoto3 from '../../../assets/images/product-3.png';

const ProductData = [
    {
        id: 1,
        imgLink: ProductPhoto1,
        title: 'Кроненпробка',
        overview: 'Пробка служит для плотного герметичного закрытия бутылок. Кроненпробки по способу снятия их с бутылки делятся на два вида: pry-off...',
        moreInfo: 'Пробка служит для плотного герметичного закрытия бутылок. Кроненпробки по способу снятия их с бутылки делятся на два вида: pry-off (для открытия бутылки требуется специальный инструмент, так называемая открывашка) и twist-off (пробку можно снять без использования инструментов, просто повернув её рукой)'
    },
    {
        id: 2,
        imgLink: ProductPhoto2,
        title: 'Twist off 82мм',
        overview: 'Наши откручивающиеся крышки — это надежные и плотные завинчивающиеся крышки для консервированных стаканов.',
        moreInfo: 'Наши откручивающиеся крышки — это надежные и плотные завинчивающиеся крышки для консервированных стаканов. В отличие от крышек с резьбой, наши откручивающиеся крышки имеют особо прочное закрытие – за счет подпружиненных кулачков. Уплотнение на внутренней стороне крышки делает ее очень герметичной и тем самым обеспечивает длительный срок хранения ваших пастеризованных/стерилизованных продуктов. Одного небольшого оборота достаточно, чтобы открыть стекло и снова закрыть его. Откручивающиеся крышки выполнены из металла. Они могут быть индивидуально напечатаны в самом высоком качестве в соответствии с вашими требованиями.'
    },
    {
        id: 3,
        imgLink: ProductPhoto3,
        title: 'Twist off 66мм',
        overview: 'Наши откручивающиеся крышки — это надежные и плотные завинчивающиеся крышки для консервированных стаканов.',
        moreInfo: 'Наши откручивающиеся крышки — это надежные и плотные завинчивающиеся крышки для консервированных стаканов. В отличие от крышек с резьбой, наши откручивающиеся крышки имеют особо прочное закрытие – за счет подпружиненных кулачков. Уплотнение на внутренней стороне крышки делает ее очень герметичной и тем самым обеспечивает длительный срок хранения ваших пастеризованных/стерилизованных продуктов. Одного небольшого оборота достаточно, чтобы открыть стекло и снова закрыть его. Откручивающиеся крышки выполнены из металла. Они могут быть индивидуально напечатаны в самом высоком качестве в соответствии с вашими требованиями.'
    }
]

export default ProductData
