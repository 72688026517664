import certificate1 from '../../assets/images/sertificate-1.jpg'
import certificate2 from '../../assets/images/sertificate-2.jpg'
import certificate3 from '../../assets/images/sertificate-3.jpg'
import './AboutPage.scss'
import VideoCard from "../../components/VideoCard";
import Footer from "../../containers/Footer";
import videoData from "./Data/videoData";
import {Helmet} from "react-helmet";
import ProducingPhoto1 from '../../assets/images/producing-1.webp'
import ProducingPhoto2 from '../../assets/images/producing-2.webp'
import ProducingPhoto3 from '../../assets/images/producing-3.webp'
import ProducingPhoto4 from '../../assets/images/producing-4.webp'

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>
                    O нас
                </title>
            </Helmet>
            <div className="location">
                <div className="container">
                    <iframe title='location-frame'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2031.9817307350913!2d69.30686861183123!3d41.22001159767039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae5e3ec5a36e1d%3A0xa0b2478ab5aaf447!2z0YPQu9C40YbQsCDQntC70YLQuNC90YLQvtC_0LPQsNC9LCDQotCw0YjQutC10L3Rgiwg0KPQt9Cx0LXQutC40YHRgtCw0L0!5e1!3m2!1sru!2s!4v1639915444564!5m2!1sru!2s"/>
                </div>
            </div>
            <div className="info-container">
                <div className="container">
                    <h3 className='title'>Информация о компании и произвоствия</h3>
                    <div className="box">
                        <div className="info">
                            <p>
                                Продукция ИП ООО «TAS SINTEZ» изготавливается на высокотехнологическом оборудовании,
                                производства Германия (ALFONS HAAR). Используются только высокачественные лакокрасочные
                                материалы, металл и компаунд, которые отвечают пищевой безопасности. На все используемое
                                сырье и материалы имеются сертификаты. Адгезионный лак, используется для внутреннего
                                покрытия крышки для хорошего сцепления компаунда с внутренней поверхностью крышки и
                                обеспечения герметичности укупорки. В зависимости от термической обработки может быть
                                использован компаунд как для стерилизации, так и для пастеризации. По требованию
                                заказчика, есть возможность брендировать крышки, а именно нанести литографическую печать
                                на внешнюю поверхность крышек. Крышки с внешней стороны покрываются покровным лаком с
                                целью избежания возможных механических повреждений, таких как царапины в процессе
                                укупорки на производственных площадях заказчиков. Готовая продукция имеет заключение
                                уполномоченных органов о подтверждении качества и пищевой безопасности. На сегодняшний
                                день на производстве технологически можно изготавливать 2 вида металлических винтовых
                                крышек с диаметрами 66 мм и 82мм.
                                Наше преимущество:
                                <ul>
                                    <li>
                                        Гарантия качества. Сырье и материалы, используемые на производстве, включая
                                        жестяные листы, лакокрасочные материалы имеют сертификаты и пищевой допуск для
                                        использования их в переработке плодоовощной культуры;
                                    </li>
                                    <li>
                                        Современная западная технология. В производстве крышек используется компаунд,
                                        который
                                        обеспечивает максимальную герметичность в процессе укупорки. В зависимости от
                                        требования
                                        заказчика и процесса термической обработки готовой продукции подбирается
                                        компаунд как
                                        для стерилизации, так и для пастеризации;
                                    </li>
                                    <li>
                                        Индивидуальность продукции. По требованию заказчика есть возможность
                                        брендировать
                                        крышки, нанести литографированную печать любой сложности.
                                    </li>
                                </ul>
                                Очень гибкий и индивидуальный подход к каждому заказчику. ДОВЕРИЕ, СОДЕЙСТВИЕ И БЫТЬ
                                ОПОРОЙ это наши основные принципы в сотрудничестве с нашими уважаемыми Заказчиками.
                            </p>
                        </div>
                        <div className="img">
                            <img src={ProducingPhoto1} alt=""/>
                            <img src={ProducingPhoto2} alt=""/>
                        </div>
                    </div>
                    <div className="box">
                        <div className="img">
                            <img src={ProducingPhoto3} alt=""/>
                            <img src={ProducingPhoto4} alt=""/>
                        </div>
                        <div className="info">
                            <p>Иностранное Предприятие Общество с Ограниченной Ответственностью «TAS SINTEZ» является
                                производителем металлической винтовой крышки тип Twist-off диаметром 82 мм и 66 мм на
                                немецком оборудовании Alfons Haar.
                                Наше производство первое в Республике Узбекистан с полным циклом производства,
                                работающее по Международными стандартам, что является гарантией качества выпускаемой
                                продукции.
                                Мы сертифицированы Международными организациями таким как: Американская компания RIR
                                CERTIFICATION PRIVATE LIMITED в области стандартов менеджмента и качества и Турецкой
                                компанией TURKCERT сертификационные услуги в области Halal.
                                ISO 9001:2015 — Система менеджмента и качества;
                                ISO 22000:2018 — Система менеджмента в области безопасности пищевой продукции;
                                Halal — Требования к продукции, производству, хранению, транспортированию, реализации и
                                маркировке.
                                ИП ООО «TAS SINTEZ» предлагает Вам полноцветную литографию любого дизайна на английском
                                оборудовании Crabtree. При лакировке и литографии используются лаки и краски,
                                разрешенные к использованию в пищевой промышленности. Мы применяем продукцию ведущих
                                производителей Германии и Италии. Наша продукция рассчитана для стерилизации и
                                пастеризации. Для изготовления наших крышек используется жесть производства Германии и
                                Китая (степень твердости DR9), внутренняя прокладка-компаунд (пластизоль) производства
                                Германии и Чехии.
                                Качество изготовленной продукции соответствует Международным требованиям к качеству и
                                эстетике. Мы предоставляем сертификат о происхождении товара (СТ-1) и Гигиенический
                                сертификат СанПин.
                                Контроль качества производит собственная лаборатория ОТК. Для отгрузки покупателю крышка
                                упакована в п/э пакет и короб из пятислойного картона.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-content">
                <div className="container">
                    {
                        videoData.map(item => (
                            <VideoCard
                                videoLink={item.videoLink}
                                title={item.title}
                                overview={item.overview}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="certificate">
                <div className="container">
                    <img src={certificate1} alt=""/>
                    <img src={certificate2} alt=""/>
                    <img src={certificate3} alt=""/>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default AboutPage