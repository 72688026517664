const videoData = [
    {
        title: 'Title',
        overview: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam assumenda aut commodi excepturi iure molestiae quasi recusandae sint tempora tempore?',
        videoLink: 'https://www.youtube.com/embed/xAU5SJlhNvU'
    },
    {
        title: 'Title',
        overview: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam assumenda aut commodi excepturi iure molestiae quasi recusandae sint tempora tempore?',
        videoLink: 'https://www.youtube.com/embed/vNuQSMUwTfI'
    },
]

export default videoData